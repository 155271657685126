import {MobileMenuActivator} from '../MobileMenuActivator';
import {IconSearch} from '~/components/Icon';
import {Button} from '~/components/Button';
import {ButtonColor, ButtonType} from '~/components/Button/Button';
import {HeaderLogoCenter} from '../HeaderLogoCenter';

interface Props {
  isMobileMenuOpen: boolean;
  handleOpenSearch: () => void;
  toggleMobileMenu: (isMobileMenuOpen: boolean) => void;
}

export function HeaderLeft({
  isMobileMenuOpen,
  handleOpenSearch,
  toggleMobileMenu,
}: Props) {
  return (
    <div className="flex pl-[1.5rem] items-center h-[5rem] w-desktop-menu-side">
      <div className="md:hidden flex mr-[2rem] pl-[1.5rem] items-center h-[5rem]">
        <MobileMenuActivator
          isMobileMenuOpen={isMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
        />
      </div>
      <button
        className="text-white relative items-center justify-center focus:ring-primary/5"
        onClick={handleOpenSearch}
      >
        <div className="flex">
          <div className="w-icon-button mr-[1rem]">
            <IconSearch className="w-[3rem]" />
          </div>
          <div className="h-[5rem] items-center hidden searchMax:flex">
            <Button
              variant={ButtonType.PILL}
              buttonColor={ButtonColor.OUTLINE}
              width="full"
            />
          </div>
        </div>
      </button>
      <div className="logoMax:hidden">
        <HeaderLogoCenter />
      </div>
    </div>
  );
}
