import {Button} from '~/components/Button';
import {ButtonColor, ButtonType} from '~/components/Button/Button';
import {Link} from '@remix-run/react';
import en from './translations/en.json';

export const IndexButtons = () => {
  return (
    <div className="mt-[5rem] mb-[4rem] mx-[2.5rem] md:mx-0 text-center indexBreak:text-left flex flex-col sm:block indexBreak:flex indexBreak:flex-row">
      <Link to={'/blogs/horsemanship'} className="mx-auto sm:mx-0">
        <Button
          as="span"
          width="full"
          variant={ButtonType.PILL}
          buttonColor={ButtonColor.LIGHTBLUE}
        >
          {en.IndexButtons.leftButton}
        </Button>
      </Link>
      <Link to={'/pages/contact'} className="mt-[4rem] sm:mt-0 mx-auto sm:mx-0">
        <Button as="span" width="full" variant={ButtonType.PILL}>
          {en.IndexButtons.rightButton}
        </Button>
      </Link>
    </div>
  );
};
