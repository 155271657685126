import {type EnhancedMenu, useIsHomePath} from '~/lib/utils';
import {useDrawer} from '~/components';
import {useCartFetchers} from '~/hooks/useCartFetchers';
import {useEffect} from 'react';
import {CartDrawer} from './components/CartDrawer';
import {MenuDrawer} from './components/MenuDrawer';
import {HeaderBar} from './components/HeaderBar';

interface Props {
  contactMenu?: EnhancedMenu;
  horsemanshipMenu?: EnhancedMenu;
  lifeMenu?: EnhancedMenu;
  menu?: EnhancedMenu;
  showCart: boolean;
  showLogin: boolean;
  title: string;
  workMenu?: EnhancedMenu;
}

export function Header({
  contactMenu,
  horsemanshipMenu,
  lifeMenu,
  menu,
  showCart,
  showLogin,
  title,
  workMenu,
}: Props) {
  const isHome = useIsHomePath();

  const {
    isOpen: isCartOpen,
    openDrawer: openCart,
    closeDrawer: closeCart,
  } = useDrawer();

  const {
    isOpen: isMobileMenuOpen,
    openDrawer: openMobileMenu,
    closeDrawer: closeMobileMenu,
  } = useDrawer();

  const addToCartFetchers = useCartFetchers('ADD_TO_CART');

  // toggle cart drawer when adding to cart
  useEffect(() => {
    if (isCartOpen || !addToCartFetchers.length) return;
    openCart();
  }, [addToCartFetchers, isCartOpen, openCart]);

  return (
    <>
      <MenuDrawer
        contactMenu={contactMenu}
        horsemanshipMenu={horsemanshipMenu}
        isMobileMenuOpen={isMobileMenuOpen}
        lifeMenu={lifeMenu}
        workMenu={workMenu}
        onCloseMobileMenu={() => {}}
      />
      <CartDrawer isOpen={isCartOpen} onClose={closeCart} />
      <header role="banner" className={'block'}>
        <HeaderBar
          isHome={isHome}
          isMobileMenuOpen={isMobileMenuOpen}
          showCart={showCart}
          showLogin={showLogin}
          openCart={openCart}
          toggleMobileMenu={isMobileMenuOpen ? closeMobileMenu : openMobileMenu}
          horsemanshipMenu={horsemanshipMenu}
        />
      </header>
    </>
  );
}
