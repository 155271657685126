import {LoadingSpinner} from './components/LoadingSpinner';

export function CartLoading() {
  return (
    <div className="flex w-full h-screen-no-nav justify-center items-center">
      {/* @todo better spinner? */}
      <LoadingSpinner />
    </div>
  );
}
