import {type EnhancedMenuItem} from '~/lib/utils';
import {Link} from '~/components';

export const FooterLink = ({item}: {item: EnhancedMenuItem}) => {
  const styles =
    'uppercase text-sub-link tracking-tight font-extrabold text-purple-highlight hover:text-white';

  if (item.to.startsWith('http')) {
    return (
      <a
        href={item.to}
        target={item.target}
        rel="noopener noreferrer"
        className={styles}
      >
        {item.title}
      </a>
    );
  }

  return (
    <Link
      to={item.to}
      target={item.target}
      prefetch="intent"
      className={styles}
    >
      {item.title}
    </Link>
  );
};
