import en from './translations/en.json';

export const LowIndexTitle = () => {
  return (
    <p
      className={`text-[4rem] font-light sm:font-[400] leading-[5rem] mt-[1rem] bg-clip-text text-transparent
      mx-[2.5rem] md:mx-0 text-center indexBreak:text-left
      bg-[radial-gradient(138.06%_1036.51%_at_95.25%_-2.54%,_#7ED4FD_14.06%,#709DF7_51.02%,#4D78EF_79.09%)]`}
    >
      {en.LowIndexTitle.text}
    </p>
  );
};
