import {type EnhancedMenu} from '~/lib/utils';
import {DropdownMenu} from '~/components/DropdownMenu';

interface Props {
  menu?: EnhancedMenu;
  onCloseMobileMenu: () => void;
}

export function MenuMobileNav({menu, onCloseMobileMenu}: Props) {
  return (
    <nav className="grid gap-4 p-6">
      <DropdownMenu menu={menu} />
    </nav>
  );
}
