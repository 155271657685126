import {Link} from '~/components';
import en from './translations/en.json';

export const Disclaimer = () => {
  const disclaimerLink = (
    <Link
      to={'/pages/disclaimer'}
      className="px-1 underline underline-offset-1 text-purple-dark hover:text-white"
    >
      {en.Disclaimer.diclaimerLink}
    </Link>
  );

  const disclaimerText = en.Disclaimer.diclaimer.split('{disclaimerLink}');

  const disclaimerMarkup = (
    <span>
      {disclaimerText[0]}
      {disclaimerLink}
      {disclaimerText[1]}
    </span>
  );

  return (
    <div className="w-full text-white px-[5rem] pb-[3rem]">
      {disclaimerMarkup}
    </div>
  );
};
