import {Image} from '@shopify/hydrogen';
import en from './translations/en.json';

export const IndexHeroImage = () => {
  return (
    <div className="px-[2.5rem] sm:px-0 mx-auto indexBreak:ml-[2rem] w-fit mt-[2rem] indexBreak:mt-[6rem]">
      <Image
        width={350}
        height={350}
        data={{
          url: 'https://cdn.shopify.com/s/files/1/0596/9723/3942/files/adam-headshot.jpg',
        }}
        className=" rounded-[50%]"
        alt={en.IndexHeroImage.alt}
      />
    </div>
  );
};
