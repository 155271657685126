import {CountrySelector} from '~/components';
import {Disclaimer} from '../Disclaimer';
import en from './translations/en.json';

interface Props {
  showLogin: boolean;
}

export function FooterLower({showLogin}: Props) {
  return (
    <div
      className={
        'bg-purple-mid md:flex flex-wrap flex-row f w-full pt-[5rem] pb-[4rem]'
      }
    >
      <Disclaimer />
      <div className={'block md:flex w-full px-[5rem]'}>
        <div
          className={
            'px-[0.4rem] py-0 justify-start flex flex-row content-center w-full items-top'
          }
        >
          {showLogin ? <CountrySelector /> : null}
        </div>
        <div
          className={
            'px-[0.4rem] py-0 pt-[3rem] md:pt-0 justify-end flex flex-row content-center w-full items-center'
          }
        >
          <span className="text-fine text-white">
            &copy;{` ${new Date().getFullYear()}, ${en.FooterLower.footerText}`}
          </span>
        </div>
      </div>
    </div>
  );
}
