import {type EnhancedMenu, useIsHomePath} from '~/lib/utils';
import {FooterMenu} from './components/FooterMenu';
import {FooterLower} from './components/FooterLower';
import {NewsletterSignup} from './components/NewsletterSignup/NewsletterSignup';
import {Section} from '~/components/Typography/Section';

interface Props {
  menu?: EnhancedMenu;
  showLogin: boolean;
}

export function Footer({menu, showLogin}: Props) {
  const isHome = useIsHomePath();
  const itemsCount = menu
    ? menu?.items?.length + 1 > 4
      ? 4
      : menu?.items?.length + 1
    : [];

  return (
    <Section
      divider="none"
      as="footer"
      role="contentinfo"
      className={`bg-purple-dark block w-full`}
      padding="none"
    >
      <NewsletterSignup />
      <FooterMenu menu={menu} showLogin={showLogin} />
      <FooterLower showLogin={showLogin} />
    </Section>
  );
}
