import {type EnhancedMenu} from '~/lib/utils';
import {Link} from '~/components';

interface Props {
  menu?: EnhancedMenu;
}

export function DropdownMenu({menu}: Props) {
  const linkClassName = `pt-[0.95rem] pr-[3.5rem] pb-[0.95rem] pl-[2rem] flex items-center leading-[1.3]
                        text-menu-link-inactive no-underline`;

  return menu?.items[0] ? (
    <nav>
      {menu?.items[0].items.length > 0 ? (
        <ul
          className="inline-flex flex-wrap list-none m-0 p-0 text-foreground cursor-pointer border-none shadow-none underline-offset-[0.3rem]
            bg-transparent text-[1.4rem] font-inherit"
        >
          <li>
            <details open={false} className={'relative block'}>
              <summary className="pr-[2.7rem] flex items-center	leading-[1.3] py-[1.2rem] no-underline">
                <span
                  className={
                    'text-menu-header hover:underline hover:underline-offset-[0.3rem] ease-in-out duration-100 transition-text-decoration'
                  }
                >
                  {menu?.items[0].title}
                </span>
                <svg
                  focusable="false"
                  role="presentation"
                  className="right-[0.8rem] text-menu-header absolute h-[0.6rem] top-caret"
                  viewBox="0 0 10 6"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z"
                    fill="currentColor"
                  ></path>
                </svg>
              </summary>
              <ul
                className={
                  'header-submenu px-[0] py-[2rem] absolute min-w-full w-[20rem] border-solid border border-menu-link-inactive'
                }
                tabIndex={-1}
              >
                {(menu?.items[0].items || []).map((item) => (
                  <li
                    key={item.id}
                    className={
                      'p-0 m-0 text-[1.3rem] leading-[1.5] tracking-[0.04rem]'
                    }
                  >
                    <Link
                      to={item.to}
                      target={item.target}
                      prefetch="intent"
                      className={linkClassName}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </details>
          </li>
        </ul>
      ) : (
        <div className={'mt-[0.7rem]'}>
          <Link
            to={menu?.items[0].to}
            target={menu?.items[0].target}
            prefetch="intent"
            className={`text-menu-header hover:underline hover:underline-offset-[0.3rem] ease-in-out duration-100 transition-text-decoration`}
          >
            {menu?.items[0].title}
          </Link>
        </div>
      )}
    </nav>
  ) : null;
}
