import {
  ActionArgs,
  AppLoadContext,
  defer,
  json,
  LoaderArgs,
} from '@shopify/remix-oxygen';
import {Suspense} from 'react';
import {Await, Link, useLoaderData} from '@remix-run/react';
import {MEDIA_FRAGMENT, PRODUCT_CARD_FRAGMENT} from '~/data/fragments';
import {getHeroPlaceholder} from '~/lib/placeholders';
import type {
  CollectionConnection,
  Customer,
  CustomerCreateInput,
  CustomerCreatePayload,
  CustomerUserError,
  Metafield,
  ProductConnection,
  UserError,
} from '@shopify/hydrogen/storefront-api-types';
import {AnalyticsPageType} from '@shopify/hydrogen';
import {IndexContent} from '~/components/IndexContent';
import {PageContentWrapper} from '~/components/Layout/components/PageContentWrapper';
import {IndexAction, IndexActions} from '~/lib/type';
import invariant from 'tiny-invariant';
interface HomeSeoData {
  shop: {
    name: string;
    description: string;
  };
}

export interface CollectionHero {
  byline: Metafield;
  cta: Metafield;
  handle: string;
  heading: Metafield;
  height?: 'full';
  loading?: 'eager' | 'lazy';
  spread: Metafield;
  spreadSecondary: Metafield;
  top?: boolean;
}

export async function loader({params, context}: LoaderArgs) {
  const {language, country} = context.storefront.i18n;

  if (
    params.lang &&
    params.lang.toLowerCase() !== `${language}-${country}`.toLowerCase()
  ) {
    // If the lang URL param is defined, yet we still are on `EN-US`
    // the the lang param must be invalid, send to the 404 page
    throw new Response(null, {status: 404});
  }

  const {shop, hero} = await context.storefront.query<{
    hero: CollectionHero;
    shop: HomeSeoData;
  }>(HOMEPAGE_SEO_QUERY, {
    variables: {handle: 'frontpage'},
  });

  return defer({
    shop,
    primaryHero: hero,
    // These different queries are separated to illustrate how 3rd party content
    // fetching can be optimized for both above and below the fold.
    featuredProducts: context.storefront.query<{
      products: ProductConnection;
    }>(HOMEPAGE_FEATURED_PRODUCTS_QUERY, {
      variables: {
        /**
         * Country and language properties are automatically injected
         * into all queries. Passing them is unnecessary unless you
         * want to override them from the following default:
         */
        country,
        language,
      },
    }),
    secondaryHero: context.storefront.query<{hero: CollectionHero}>(
      COLLECTION_HERO_QUERY,
      {
        variables: {
          handle: 'saddles',
          country,
          language,
        },
      },
    ),
    featuredCollections: context.storefront.query<{
      collections: CollectionConnection;
    }>(FEATURED_COLLECTIONS_QUERY, {
      variables: {
        country,
        language,
      },
    }),
    tertiaryHero: context.storefront.query<{hero: CollectionHero}>(
      COLLECTION_HERO_QUERY,
      {
        variables: {
          handle: 'leatherworking-tools',
          country,
          language,
        },
      },
    ),
    analytics: {
      pageType: AnalyticsPageType.home,
    },
  });
}

export async function action({request, context}: ActionArgs) {
  const {session, storefront} = context;
  const headers = new Headers();
  let status = 200;
  try {
    const [formData, customerAccessToken] = await Promise.all([
      request.formData(),
      session.get('customerAccessToken'),
    ]);

    const indexAction = formData.get('indexAction') as IndexActions;
    invariant(indexAction, 'No indexAction defined');

    let result: {
      errors?: UserError[];
    };

    switch (indexAction) {
      case IndexAction.CONTACT:
        const contactName =
          formData.get('contact[Name')?.valueOf().toString() || '';

        status = 200;
        return json(
          {
            ok: false,
            data: undefined,
            errors: [{message: 'Sorry this form is not available right now'}],
            analytics: {},
          },
          {status, headers},
        );
      case IndexAction.NEWSLETTER_SIGNUP:
        const email = formData.get('newsletterEmail');

        const emailAddress: string = email?.valueOf().toString() || '';

        const {customer, customerUserErrors} = await customerCreate({
          input: {
            acceptsMarketing: true,
            password: 'signup',
            email: emailAddress,
          },
          storefront,
        });

        status = 200;
        return json(
          {
            ok: true,
            data: customer,
            errors: customerUserErrors,
            analytics: {},
          },
          {status, headers},
        );

      default:
        invariant(false, `${indexAction} index action is not defined`);
    }

    // const {cart, errors} = result;
    // return json(
    //   {
    //     cart,
    //     errors,
    //     analytics: {
    //       cartId,
    //     },
    //   },
    //   {status, headers},
    // );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    status = 500;
    return json(
      {
        ok: false,
        errors: [{message: 'Please try again later'}],
        analytics: {},
      },
      {status, headers},
    );
  }
}

/**
 * Create new customer
 * @param input CustomerCreateMutation https://shopify.dev/docs/api/storefront/2023-01/mutations/customerCreate
 * @see https://shopify.dev/api/storefront/2022-01/mutations/cartcreate
 * @returns result {customer, customerUserErrors}
 * @preserve
 */
export async function customerCreate({
  input,
  storefront,
}: {
  input: CustomerCreateInput;
  storefront: AppLoadContext['storefront'];
}) {
  const {customerCreate} = await storefront.mutate<{
    customerCreate: CustomerCreatePayload;
  }>(CUSTOMER_CREATE_MUTATION, {
    variables: {
      input,
    },
  });

  invariant(customerCreate, 'No data returned from customerCreate mutation');
  return customerCreate;
}

const CUSTOMER_CREATE_MUTATION = `#graphql
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        email
        acceptsMarketing
      }
      customerUserErrors {
        message
      }
    }
  }
`;

export default function Homepage() {
  const {
    primaryHero,
    secondaryHero,
    tertiaryHero,
    featuredCollections,
    featuredProducts,
  } = useLoaderData<typeof loader>();

  // TODO: skeletons vs placeholders
  const skeletons = getHeroPlaceholder([{}, {}, {}]);

  // TODO: analytics
  // useServerAnalytics({
  //   shopify: {
  //     pageType: ShopifyAnalyticsConstants.pageType.home,
  //   },
  // });

  return <IndexContent />;
}

const COLLECTION_CONTENT_FRAGMENT = `#graphql${MEDIA_FRAGMENT}
  fragment CollectionContent on Collection {
    id
    handle
    title
    descriptionHtml
    heading: metafield(namespace: "hero", key: "title") {
      value
    }
    byline: metafield(namespace: "hero", key: "byline") {
      value
    }
    cta: metafield(namespace: "hero", key: "cta") {
      value
    }
    spread: metafield(namespace: "hero", key: "spread") {
      reference {
        ...Media
      }
    }
    spreadSecondary: metafield(namespace: "hero", key: "spread_secondary") {
      reference {
        ...Media
      }
    }
  }
`;

const HOMEPAGE_SEO_QUERY = `#graphql${COLLECTION_CONTENT_FRAGMENT}
  query collectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
    shop {
      name
      description
    }
  }
`;

const COLLECTION_HERO_QUERY = `#graphql${COLLECTION_CONTENT_FRAGMENT}
  query collectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
  }
`;

// @see: https://shopify.dev/api/storefront/latest/queries/products
export const HOMEPAGE_FEATURED_PRODUCTS_QUERY = `#graphql${PRODUCT_CARD_FRAGMENT}
  query homepageFeaturedProducts($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    products(first: 8) {
      nodes {
        ...ProductCard
      }
    }
  }
`;

// @see: https://shopify.dev/api/storefront/latest/queries/collections
export const FEATURED_COLLECTIONS_QUERY = `#graphql
  query homepageFeaturedCollections($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    collections(
      first: 4,
      sortKey: UPDATED_AT
    ) {
      nodes {
        id
        title
        handle
        image {
          altText
          width
          height
          url
        }
      }
    }
  }
`;
