import {IconAccount, IconSearch, Link, IconMenu, Button} from '~/components';
import {CartCount} from '../CartCount';
import {useState} from 'react';
import {SearchOverlay} from './components/SearchOverlay/SearchOverlay';
import {MobileMenuActivator} from './components/MobileMenuActivator';
import {ButtonColor, ButtonType} from '~/components/Button/Button';
import {HeaderLogoCenter} from './components/HeaderLogoCenter';
import {EnhancedMenu} from '~/lib/utils';
import {HeaderMenuBar} from './components/HeaderRight/components/HeaderMenubar';
import {HeaderLeft} from './components/HeaderLeft';
import {HeaderRight} from './components/HeaderRight';

interface Props {
  isHome: boolean;
  isMobileMenuOpen: boolean;
  horsemanshipMenu?: EnhancedMenu;
  showCart: boolean;
  showLogin: boolean;
  openCart: () => void;
  toggleMobileMenu: (isMobileMenuOpen: boolean) => void;
}

export function HeaderBar({
  isHome,
  isMobileMenuOpen,
  showCart,
  horsemanshipMenu,
  showLogin,
  openCart,
  toggleMobileMenu,
}: Props) {
  const [searchOpen, setSearchOpen] = useState(false);

  function handleClose() {
    setSearchOpen(false);
  }

  function handleOpenSearch() {
    setSearchOpen(true);
  }

  return (
    <>
      <SearchOverlay handleClose={handleClose} open={searchOpen} />
      <div
        className={`absolute ${
          isHome ? 'bg-transparent' : 'bg-blue-dark'
        } flex w-full h-[5rem]`}
      >
        <HeaderLeft
          isMobileMenuOpen={isMobileMenuOpen}
          handleOpenSearch={handleOpenSearch}
          toggleMobileMenu={toggleMobileMenu}
        />
        <div className="hidden logoMax:block">
          <HeaderLogoCenter />
        </div>
        <HeaderRight
          isHome
          horsemanshipMenu={horsemanshipMenu}
          showCart={showCart}
          showLogin={showLogin}
          openCart={openCart}
        />
      </div>
    </>
  );
}
