export function HeaderLogoCenter() {
  return (
    <div className="w-[50px]">
      <section
        className={
          'rounded-half overflow-hidden block border-solid border-purple-dark border-3 w-[5rem] h-[5rem] mx-auto '
        }
      >
        <h1 className="header-h1">
          <a href="/" className="inline-block no-underline break-words">
            <div className="p-0.5 bg-white w-[5rem] h-[5rem]">
              <img
                src="//cdn.shopify.com/s/files/1/0596/9723/3942/files/SignatureLogoSquare_150x.png?v=1642786391"
                loading="lazy"
                className="fadeIn left-[5px] top-[4px] relative w-[3.5rem] h-[3.5rem]"
                width={886}
                height={886}
                alt="AdamTill.com"
              />
            </div>
          </a>
        </h1>
      </section>
    </div>
  );
}
