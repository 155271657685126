import {Button} from '~/components/Button';
import {ButtonColor, ButtonType} from '~/components/Button/Button';
import {Link} from '@remix-run/react';
import {Image} from '@shopify/hydrogen';
import en from './translations/en.json';
import {IndexHeroImage} from './components/IndexHeroImage';
import {IndexButtons} from './components/IndexButtons';
import {LowIndexTitle} from './components/LowIndexTitle';
import {MidIndexBody} from './components/MidIndexBody';
import {HighIndexTitle} from './components/HighIndexTitle';

export const IndexContentHeader = () => {
  const fadeFromColor = '#4D78EF';
  const fadeMidColor = '#709DF7';
  const fadeToColor = '#7ED4FD';

  return (
    <header className="w-full h-[112rem] sm:h-[100rem] md:h-[100rem] indexBreak:h-[70rem] pt-[4rem] flex items-center bg-gradient-to-br from-[#0e1520] to-[#0f1b29]">
      <div
        id="header-content"
        className="flex flex-col indexBreak:flex-row mx-auto w-fit"
      >
        <div className="">
          <HighIndexTitle />
          <MidIndexBody />
          <LowIndexTitle />
          <IndexButtons />
        </div>
        <IndexHeroImage />
      </div>
    </header>
  );
};
