interface Props {
  toggleMobileMenu: (isMobileMenuOpen: boolean) => void;
  isMobileMenuOpen: boolean;
}

export function MobileMenuActivator({
  isMobileMenuOpen,
  toggleMobileMenu,
}: Props) {
  function handleClick(isMobileMenuOpen: boolean): void {
    toggleMobileMenu(isMobileMenuOpen);
  }

  return (
    <nav role="navigation">
      <div className="z-[20] relative">
        <button
          onClick={() => handleClick(isMobileMenuOpen)}
          className="block relative w-fit select-none"
        >
          <span
            className={`menubar ${isMobileMenuOpen ? 'menubar_checked' : ''}`}
          ></span>
          <span
            className={`menubar ${
              isMobileMenuOpen ? 'menubar_checked_middle' : ''
            }`}
          ></span>
          <span
            className={`menubar ${
              isMobileMenuOpen ? 'menubar_checked_last' : ''
            }`}
          ></span>
        </button>
      </div>
    </nav>
  );
}
