import en from './translations/en.json';

export const MidIndexBody = () => {
  return (
    <>
      {/* Desktop text */}
      <p className="hidden md:block text-grey-body font-[400] mt-[1rem] text-[3rem] text-center indexBreak:text-left">
        {en.MidIndexBody.firstLine}
      </p>
      <p className="hidden md:block text-grey-body font-[400] text-[3rem] text-center indexBreak:text-left">
        {en.MidIndexBody.secondLine}
      </p>
      {/* Mobile text */}
      <p className="block md:hidden mx-[2.5rem] text-grey-body font-[400] text-[2.5rem] text-center indexBreak:text-left">
        {en.MidIndexBody.combined}
      </p>
    </>
  );
};
