interface Props {
  children?: React.ReactNode;
}

export function PageContentWrapper({children}: Props) {
  return (
    <div
      className={`mt-[6rem] mb-[12rem] px-[2rem] md:px-[12rem] break-words page-content-wrapper `}
    >
      {children}
    </div>
  );
}
