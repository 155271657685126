import {PageContentWrapper} from '../Layout/components/PageContentWrapper';
import {PictureBlockSideText} from '../Layout/components/PictureBlockSideText';
import {
  FlexDirection,
  ImagePosition,
} from '../Layout/components/PictureBlockSideText/PictureBlockSideText';
import {Section} from '../Typography/Section';
import {Text} from '../Typography/Text';
import en from './translations/en.json';
import {IndexContentHeader} from './components/IndexContentHeader';

export function IndexContent() {
  return (
    <Section className="w-full" padding={'none'}>
      <IndexContentHeader />
      <PageContentWrapper>
        {/* <PictureBlockSideText
          imgAlt="Icelandic horse tacked up in traditional two-rein western gear, looking off to the right"
          imgSrc="https://cdn.shopify.com/s/files/1/0596/9723/3942/files/tindur-two-rein-quarter-front.jpg?v=1680384959"
          imgPosition={ImagePosition.LEFT}
        >
          {'things'}
        </PictureBlockSideText>
        <Text as="p">{'I have a confession to make...'}</Text>
        <Text as="p">{'I LOVE a good argument.'}</Text>
        <Text as="p">
          {`I'm not talking about a polite discussion between people who both
        present ideas in a reasonable fashion.`}
        </Text>
        <Text as="p">
          {`I'm talking about when two people care so much about the topic they're
        debating that they literally get out of the shower or sit up in bed at
        midnight to spend 20 mins crafting the perfect reply to each other.`}
        </Text>
        <Text as="p">
          {`Soap in the eyes only stings for a bit, and sleep is only for people who
        don't care if people on the internet are wrong, after all.`}
        </Text>
        <Text as="p">
          {`Now, there are rules to this perfect argument, so make no mistake. Let
        me knock off just a few...`}
        </Text>
        <PictureBlockSideText
          imgAlt={
            'referee with text saying we have an ad hominem attack, personal foul, attacked the opponent instead of his argument'
          }
          imgSrc={
            'https://cdn.shopify.com/s/files/1/0596/9723/3942/files/ad-hominem-attack.jpg?v=1680326221'
          }
          imgPosition={ImagePosition.RIGHT}
          flexDirection={FlexDirection.COLUMN}
        >
          <Text as="p">
            {`Attacking the person rather than what they're presenting gets you
          kicked off the field without prejudice.`}
          </Text>
          <Text as="p">
            {`If your only point is to call someone a libtard Parelli-worshipping
          clicker-lover, let's be honest...you've run out of ideas. Take the L,
          walk away, and be ashamed of it.`}
          </Text>
        </PictureBlockSideText>

        <Text as="p">
          {`Likewise, if I ask you why you claim something and you tell me that your
        trainer said it, it's obvious, and that everyone knows it to be true,
        I'll just walk away with sad smile and profound sense of disappointment.`}
        </Text>
        <Text as="p">
          {`This was all going so well, and now it's basically over...cue the sound
        of the sadly deflating balloon.`}
        </Text>
        <Text as="p">
          {`If you don't know something and admit it, we'll totally be friends from
        that point on. Let's be honest, I'll probably spend way too many hours
        digging through my books or somewhere on a website last updated in 1997
        looking for a quote I swear I sort of remember.`}
        </Text>
        <Text as="p">
          {`But if you prove me wrong, I'll hate you...and totally love you for
        doing it.`}
        </Text>
        <Text as="p">
          {`This site is bascially glorified love note to what author Tim Urban
        might call "high-rung horsemanship" assuming he knew which end of a
        horse to feed (no judgement if not Tim).`}
        </Text>
        <Text as="p">
          {`High rung and low rung are terms I first heard while reading his book on
        why society sort of sucks right now. In a world where low rung political
        debates are no more fun then watching kids on a playground call each
        other stupid doo-doo heads, high run minds love to wring out a topic in
        a debate that gets to the heart of something no matter where it leads.`}
        </Text>
        <Text as="p">
          {`To illustrate the point, most people get the saddles from tack stores.
        Some folks call up a local saddle fitter instead. Others commission a
        custom saddle maker to end up with the perfect tool.`}
        </Text>
        <Text as="p">
          {`These people are often convinced they have the perfect saddle because it
        was "expensive"; no matter what the white patches of hair on their
        girth-sour horse might be hinting at.`}
        </Text>
        <Text as="p">
          {`Me, I tried most of that. When nobody could explain which of five
        competing theories of saddle fitting was correct, the tack stores were
        filled with junk that looked like someone skinned Barney for the
        leather, yet another saddlemaker missed a ship date he SWORE he'd make,
        and I realized I couldn't afford the old Calfornia saddle I really
        wanted anyway, I did what any sensible horse owner would do.`}
        </Text>
        <Text as="p">
          {`I spent five years learning enough about trees, saddles and
        leatherworking to just do it myself. And it's yet to fall apart (go me).`}
        </Text>
        <Text as="p">
          {`I didn't plan on buying my first horse and pulling his shoes myself the
        day the cheque cleared just to stop having the same arguments with the
        vet and farrier about what was wrong with my lesson horse, but THAT
        happened too.`}
        </Text>
        <Text as="p">
          {`If none of that sounds silly to you, or you're just curious about the
        mad man who thinks that sounds sensible, feel free to poke around.
        Somewhere in the inane ramblings you might find something of use.`}
        </Text>
        <Text as="p">
          {`Oh, and I'm a software developer btw. I could have used one of the free
        website themes that my employer Shopify provided me for free to create
        this site, but instead I built it all basically from the ground up. You
        know, because that apparently makes sense.`}
        </Text>
        <Text as="p">{`Nice to make your aquaintance!`}</Text> */}
      </PageContentWrapper>
    </Section>
  );
}
