import {useIsHydrated} from '~/hooks/useIsHydrated';
import {useMemo} from 'react';
import {IconBag, Link} from '~/components';

export function Badge({
  openCart,
  dark,
  count,
}: {
  count: number;
  dark: boolean;
  openCart: () => void;
}) {
  const isHydrated = useIsHydrated();

  const BadgeCounter = useMemo(
    () => (
      <>
        <IconBag />
        <div
          className={`${
            dark
              ? 'text-white bg-contrast dark:text-contrast dark:bg-primary'
              : 'text-white bg-primary'
          } absolute bottom-1 right-1 text-[0.625rem] font-medium subpixel-antialiased h-3 min-w-[0.75rem]
          flex items-center justify-center leading-none text-center rounded-full w-auto px-[0.125rem] pb-px`}
        >
          <span>{count || 0}</span>
        </div>
      </>
    ),
    [count, dark],
  );

  return isHydrated ? (
    <button
      onClick={openCart}
      className="text-white relative flex items-center justify-center w-icon-button h-icon-button focus:ring-primary/5"
    >
      {BadgeCounter}
    </button>
  ) : (
    <Link
      to="/cart"
      className="text-white relative flex items-center justify-center w-icon-button h-icon-button focus:ring-primary/5"
    >
      {BadgeCounter}
    </Link>
  );
}
