import {
  Form,
  useActionData,
  useFetcher,
  useSubmit,
  useTransition,
} from '@remix-run/react';
import {SyntheticEvent, useEffect, useState} from 'react';
import {LoadingSpinner} from '~/components/CartLoading/components/LoadingSpinner';
import {IconArrow, IconCheck} from '~/components/Icon';
import {Input, InputType} from '~/components/Input/Input';
import {IndexAction} from '~/lib/type';
import en from './translations/en.json';
import * as gtag from '~/utils/gtags.client';

export function NewsletterSignup() {
  const fetcher = useFetcher();
  const [displayError, setDisplayError] = useState(undefined);
  const [formStatus, setFormStatus] = useState<string | undefined>(undefined);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const formErrors = fetcher.data?.errors;
    const displayError =
      formErrors && formErrors.length > 0 ? formErrors[0].message : undefined;
    setDisplayError(displayError);
    setFormSubmitted((fetcher.data?.ok && !displayError) || false);
    setFormStatus(fetcher.state);
  }, [fetcher]);

  const FORM_ID = 'pageFooterNewsletterSignup';
  const FORM_NAME = 'Newsletter Signup';
  const FORM_DESTINATION = 'Shopify';

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    // const target = e.target as typeof e.target & {
    //   newsletterEmail: {value: string};
    // };

    gtag.event({
      action: 'submit_form',
      form_id: FORM_ID,
      form_name: FORM_NAME,
      form_destination: FORM_DESTINATION,
    });
  };

  return (
    <div className={'w-full text-small-body relative top-[4rem] -mt-[10rem]'}>
      <div
        className={`m-auto w-fit
        bg-size-200 bg-pos-0 hover:bg-pos-100 bg-gradient-to-r duration-500 from-purple-mid to-purple-mid-trans
        block md:flex flex-row pt-[4rem] pb-[4rem] px-[5rem]`}
      >
        <h3
          className={'text-small-body tracking-small-body pt-[1rem] text-white'}
        >
          {en.NewsletterSignup.tagLine}
        </h3>
        <div>
          <fetcher.Form
            id={FORM_ID}
            name={FORM_NAME}
            method="post"
            className="flex relative items-center gap-2 border border-white text-white bg-purple-mid
                       mt-[3rem] md:mt-0 md:ml-[2rem] py-[1rem] px-[3rem] w-full"
            onSubmit={handleSubmit}
          >
            <input
              type="hidden"
              name="indexAction"
              value={IndexAction.NEWSLETTER_SIGNUP}
            />
            <div className="h-[38px] w-full items-center flex">
              <Input
                className={
                  'w-full leading-6 tracking-wider text-input mid-purple-autofill'
                }
                type="email"
                variant={InputType.TEXT}
                name="newsletterEmail"
                id="Search-In-Modal"
                label={en.NewsletterSignup.email}
                haserrorhandling="true"
                successMessage={
                  formSubmitted
                    ? 'Check your email to see your welcome message'
                    : undefined
                }
                validationError={displayError}
              />
              <div className="w-[38px]">
                {formStatus === 'idle' ? (
                  <button
                    type="submit"
                    className="relative flex mt-0 mx-auto items-center justify-center w-[3rem] h-[3rem] focus:ring-primary/5"
                  >
                    {formSubmitted ? <IconCheck /> : <IconArrow />}
                  </button>
                ) : (
                  <LoadingSpinner />
                )}
              </div>
            </div>
          </fetcher.Form>
        </div>
      </div>
    </div>
  );
}
