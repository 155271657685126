import {useParams, Form} from '@remix-run/react';
import {Input, IconSearch} from '~/components';
import {InputType} from '~/components/Input/Input';
import en from './translations/en.json';
import {motion} from 'framer-motion';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export function SearchOverlay({open, handleClose}: Props) {
  const params = useParams();
  return open ? (
    <motion.nav
      initial={{opacity: 0, scale: 0.5}}
      animate={{opacity: 1, scale: 1}}
      transition={{duration: 0.3}}
    >
      <div className="transition_modal absolute text-white t-full bg-purple-dark flex items-center justify-center z-20 w-full px-[calc(25vw)] py-[6rem] leading-loose">
        <Form
          method="get"
          action={params.lang ? `/${params.lang}/search` : '/search'}
          className="flex relative items-center gap-2 border border-white text-white bg-purple-dark py-[1rem] px-[3rem] w-full"
          onSubmit={handleClose}
        >
          <Input
            className={''}
            type="search"
            variant={InputType.TEXT}
            name="headerSearch"
            id="Search-In-Modal"
            label={en.SearchOverlay.search}
          />
          <button
            type="submit"
            className="relative flex items-center justify-center w-[3rem] h-[3rem] focus:ring-primary/5"
          >
            <IconSearch />
          </button>
        </Form>
        <button
          className="search-modal__close-button modal__close-button link link--text focus-inset w-[4rem] h-[4rem]"
          aria-label="Close"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            focusable="false"
            role="presentation"
            className="icon icon-close h-[1.7rem] hover:h-[1.9rem] w-[1.7rem] hover:w-[1.9rem] my-0 mx-auto"
            fill="white"
            viewBox="0 0 18 17"
          >
            <path
              d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div
        className={
          'absolute t-full bg-overlay justify-center z-10 w-full h-full'
        }
        onClick={handleClose}
        onKeyDown={handleClose}
        role="button"
        tabIndex={-1}
        aria-label={en.SearchOverlay.return}
      ></div>
    </motion.nav>
  ) : null;
}
