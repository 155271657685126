import {type EnhancedMenu, type EnhancedMenuItem} from '~/lib/utils';
import {Disclosure} from '@headlessui/react';
import {IconCaret} from '~/components';
import {Suspense} from 'react';
import {FooterLink} from '../FooterLink';
import {Heading} from '~/components/Typography/Heading';

interface Props {
  menu?: EnhancedMenu;
  showLogin: boolean;
}

export function FooterMenu({menu, showLogin}: Props) {
  const styles = {
    section: 'grid gap-4',
    nav: 'grid gap-2 pb-6',
  };

  const youtubeItem: EnhancedMenuItem = {
    to: 'https://www.youtube.com/channel/UCWElEpvcNm8oh1-5DR5DR-g',
    target: '_blank',
    isExternal: true,
    items: [],
    id: 'youtube',
    tags: [],
    title: 'YouTube',
    type: 'HTTP',
  };
  const twitterItem: EnhancedMenuItem = {
    to: 'https://twitter.com/ArcticLightsAq',
    target: '_blank',
    isExternal: true,
    items: [],
    id: 'youtube',
    tags: [],
    title: 'Twitter',
    type: 'HTTP',
  };
  const facebookItem: EnhancedMenuItem = {
    to: 'https://www.facebook.com/aktill',
    target: '_blank',
    isExternal: true,
    items: [],
    id: 'youtube',
    tags: [],
    title: 'Facebook',
    type: 'HTTP',
  };
  const linkedinItem: EnhancedMenuItem = {
    to: 'https://www.linkedin.com/in/adam-till-51114a98/',
    target: '_blank',
    isExternal: true,
    items: [],
    id: 'youtube',
    tags: [],
    title: 'LinkedIn',
    type: 'HTTP',
  };

  return (
    <div className="mt-[9rem] mb-[5rem]">
      <div className={'mx-auto md:w-fit flex flex-wrap'}>
        {(menu?.items || []).map((item: EnhancedMenuItem) => (
          <div
            key={item.id}
            className={'ml-[6rem] mr-auto md:mx-[3rem] w-[13rem] md:w-fit'}
          >
            <div>
              <Heading
                className="flex justify-between uppercase text-copy font-extrabold text-purple-highlight hover:text-white"
                size="lead"
                as="h3"
              >
                {item.title}
              </Heading>
              {item.title === 'Follow' ? (
                <div className={'overflow-hidden transition-all duration-300'}>
                  <Suspense data-comment="This suspense fixes a hydration bug in Disclosure.Panel with static prop">
                    <nav className={styles.nav}>
                      <FooterLink key={'youtube'} item={youtubeItem} />
                      <FooterLink key={'twitter'} item={twitterItem} />
                      <FooterLink key={'facebook'} item={facebookItem} />
                      <FooterLink key={'linkedin'} item={linkedinItem} />
                    </nav>
                  </Suspense>
                </div>
              ) : item?.items?.length > 0 ? (
                <div className={'overflow-hidden transition-all duration-300'}>
                  <Suspense data-comment="This suspense fixes a hydration bug in Disclosure.Panel with static prop">
                    <nav className={styles.nav}>
                      {item.items.map((subItem) =>
                        !showLogin && subItem.title === 'Login' ? null : (
                          <FooterLink key={subItem.id} item={subItem} />
                        ),
                      )}
                    </nav>
                  </Suspense>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
