import {forwardRef} from 'react';
import {Link} from '@remix-run/react';
import clsx from 'clsx';

import {missingClass} from '~/lib/utils';

interface Props {
  as?: React.ElementType;
  buttonColor?: ButtonColor;
  className?: string;
  variant: ButtonType;
  width?: 'auto' | 'full';
  [key: string]: any;
}

export enum ButtonColor {
  LIGHTBLUE = 'LIGHTBLUE',
  OUTLINE = 'OUTLINE',
}

export enum ButtonType {
  INLINE = 'INLINE',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  SUBMIT = 'SUBMIT',
  PILL = 'PILL',
}

export const Button = forwardRef(
  (
    {
      as = 'button',
      buttonColor,
      className = '',
      variant = ButtonType.PRIMARY,
      width = 'auto',
      ...props
    }: Props,
    ref,
  ) => {
    const Component = props?.to ? Link : as;

    const baseButtonClasses = `inline-block font-medium text-center pt-[1rem] hover:pt-[1.1rem] pb-[1.2rem]
      hover:pb-[1.3rem] px-[5rem] hover:px-[5.1rem] m-[0.1rem] hover:m-0`;

    let buttonColorClasses =
      'border-white bg-slate-700 text-white hover:border-white hover:bg-slate-600 focus:ring-white focus:ring-offset-slate-900';
    switch (buttonColor) {
      case ButtonColor.LIGHTBLUE:
        buttonColorClasses =
          'bg-sky-300 hover:bg-sky-200 text-slate-900 focus:ring-white focus:ring-offset-slate-900';
        break;
      case ButtonColor.OUTLINE:
        buttonColorClasses = 'bg-transparent';
        break;
    }

    const variants = {
      PRIMARY: `${baseButtonClasses} bg-primary hover:bg-primary-hover text-contrast`,
      SECONDARY: `${baseButtonClasses} border border-primary bg-contrast hover:bg-primary-contrast text-primary`,
      INLINE: 'border-b border-primary leading-none pb-1',
      SUBMIT: `${baseButtonClasses} bg-primary hover:bg-primary-hover text-contrast`,
      PILL: `${
        buttonColor === ButtonColor.OUTLINE ? 'pl-10 pr-[12rem]' : 'px-10'
      } py-4 rounded-full mr-6 font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 border ${buttonColorClasses}`,
    };

    const widths = {
      auto: 'w-auto',
      full: 'w-full',
    };

    const styles = clsx(
      missingClass(className, 'bg-') && variants[variant],
      missingClass(className, 'w-') && widths[width],
      className,
    );

    return (
      <Component
        // @todo: not supported until react-router makes it into Remix.
        // preventScrollReset={true}
        className={styles}
        {...props}
        ref={ref}
      />
    );
  },
);
