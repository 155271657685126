import {Await, useMatches} from '@remix-run/react';
import {Suspense} from 'react';
import {Badge} from '../Badge';

export function CartCount({
  isHome,
  openCart,
}: {
  isHome: boolean;
  openCart: () => void;
}) {
  const [root] = useMatches();

  return (
    <div className="flex items-center">
      <Suspense
        fallback={<Badge count={0} dark={isHome} openCart={openCart} />}
      >
        <Await resolve={root.data?.cart}>
          {(cart) => (
            <Badge
              dark={false}
              openCart={openCart}
              count={cart?.totalQuantity || 0}
            />
          )}
        </Await>
      </Suspense>
    </div>
  );
}
