import clsx from 'clsx';
import {Heading} from '../Heading';

interface Props {
  children?: React.ReactNode;
  className?: string;
  heading?: string;
  variant?: 'default' | 'article' | 'allCollections' | 'blog';
  [key: string]: any;
}

export function PageHeader({
  children,
  className,
  heading,
  variant = 'default',
  ...props
}: Props) {
  const classes = 'cms_content';
  const defaultClasses = classes; //'grid w-full gap-8 p-6 py-8 md:p-8 lg:p-12 justify-items-start'
  const articleClasses = classes; //grid md:text-center w-full gap-4 p-6 py-8 md:p-8 lg:p-12 md:justify-items-center'
  const blogClasses = classes; //'flex justify-between items-baseline gap-8 p-6 md:p-8 lg:p-12'
  const allCollectionsClasses = classes; //'flex justify-between items-baseline gap-8 p-6 md:p-8 lg:p-12');
  const formClasses = classes;

  const variants: Record<string, string> = {
    default: defaultClasses,
    article: articleClasses,
    blog: blogClasses,
    allCollections: allCollectionsClasses,
    form: formClasses,
  };

  const styles = clsx(variants[variant], className);

  return (
    <header {...props} className={styles}>
      {heading && (
        <Heading as="h1" width="narrow" size="heading" className="inline-block">
          {heading}
        </Heading>
      )}
      {children}
    </header>
  );
}
