import {type EnhancedMenu} from '~/lib/utils';
import {DropdownMenu} from '~/components/DropdownMenu';
import {Link} from '@remix-run/react';

interface Props {
  horsemanshipMenu?: EnhancedMenu;
}

export function HeaderMenuBar({horsemanshipMenu}: Props) {
  return (
    <div className={'relative hidden row w-full md:flex'}>
      <section className={'w-[45rem]'}>
        <div className={'w-full flex justify-around'}>
          <div className={'w-[17rem] mr-[2rem]'}>
            <DropdownMenu menu={horsemanshipMenu} />
          </div>
          <div className={'w-[17rem]'}>
            <div className="items-center mx-auto w-fit flex h-[5rem] text-menu-header">
              <Link to="/pages/about">About</Link>
            </div>
          </div>
          <div className={'w-[17rem]'}>
            <div className="items-center mx-auto w-fit flex h-[5rem] text-menu-header">
              <Link to="/pages/contact">Contact</Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
