import {Drawer} from '~/components';
import {type EnhancedMenu} from '~/lib/utils';
import {MenuMobileNav} from '../MenuMobileNav';

interface Props {
  contactMenu?: EnhancedMenu;
  horsemanshipMenu?: EnhancedMenu;
  isMobileMenuOpen: boolean;
  lifeMenu?: EnhancedMenu;
  workMenu?: EnhancedMenu;
  onCloseMobileMenu: () => void;
}

export function MenuDrawer({
  contactMenu,
  horsemanshipMenu,
  isMobileMenuOpen,
  lifeMenu,
  workMenu,
  onCloseMobileMenu,
}: Props) {
  return (
    <Drawer open={isMobileMenuOpen} onClose={onCloseMobileMenu} openFrom="left">
      <div className="grid">
        <MenuMobileNav
          menu={contactMenu}
          onCloseMobileMenu={onCloseMobileMenu}
        />
        <MenuMobileNav
          menu={horsemanshipMenu}
          onCloseMobileMenu={onCloseMobileMenu}
        />
        <MenuMobileNav menu={lifeMenu} onCloseMobileMenu={onCloseMobileMenu} />
        <MenuMobileNav menu={workMenu} onCloseMobileMenu={onCloseMobileMenu} />
      </div>
    </Drawer>
  );
}
