import {Button, ButtonType} from './Button/Button';
import {PageContentWrapper} from './Layout/components/PageContentWrapper';
import {PageHeader} from './Typography/PageHeader';
import {Text} from './Typography/Text';

export function NotFound({type = 'page'}: {type?: string}) {
  const heading = `We’ve lost this ${type}`;
  const description = `We couldn’t find the ${type} you’re looking for. Try checking the URL or heading back to the home page.`;

  return (
    <PageContentWrapper>
      <PageHeader heading={heading}>
        <Text width="narrow" as="p">
          {description}
        </Text>
        <Button width="auto" variant={ButtonType.SECONDARY} to={'/'}>
          Take me to the home page
        </Button>
      </PageHeader>
    </PageContentWrapper>
  );
}
