import {HeaderMenuBar} from './components/HeaderMenubar';
import {Link} from '@remix-run/react';
import {IconAccount} from '~/components/Icon';
import {CartCount} from '../../../CartCount';
import {EnhancedMenu} from '~/lib/utils';

interface Props {
  isHome: boolean;
  horsemanshipMenu?: EnhancedMenu;
  showCart: boolean;
  showLogin: boolean;
  openCart: () => void;
}

export function HeaderRight({
  isHome,
  horsemanshipMenu,
  showCart,
  showLogin,
  openCart,
}: Props) {
  return (
    <div className="w-desktop-menu-side h-[5rem] pr-[1.5rem]">
      <div className="float-right flex h-[5rem]">
        <HeaderMenuBar horsemanshipMenu={horsemanshipMenu} />
        {showLogin ? (
          <div
            className={`flex items-center text-white ${
              showLogin && showCart ? 'mr-[1rem]' : ''
            }`}
          >
            <Link
              to="/account"
              className="relative flex items-center justify-center w-icon-button h-icon-button focus:ring-primary/5"
            >
              <IconAccount />
            </Link>
          </div>
        ) : null}
        {showCart ? <CartCount isHome={isHome} openCart={openCart} /> : null}
      </div>
    </div>
  );
}
