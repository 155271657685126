import type {LayoutData} from '../../root';
import {Header} from './components/Header';
import {Footer} from './components/Footer';

interface Props {
  children: React.ReactNode;
  layout: LayoutData;
}

const LOGIN_HIDDEN = true;
const CART_HIDDEN = true;

export function Layout({children, layout}: Props) {
  return (
    <div className={'min-h-screen relative'}>
      <div className="flex flex-col bg-background">
        <div className="">
          <a href="#mainContent" className="sr-only">
            Skip to content
          </a>
        </div>
        <Header
          title={layout?.shop.name ?? 'Hydrogen'}
          contactMenu={layout?.contactMenu}
          horsemanshipMenu={layout?.horsemanshipMenu}
          lifeMenu={layout?.lifeMenu}
          showCart={!CART_HIDDEN}
          showLogin={!LOGIN_HIDDEN}
          workMenu={layout?.workMenu}
        />
        <main role="main" id="mainContent" className="">
          {children}
        </main>
      </div>
      <Footer menu={layout?.footerMenu} showLogin={!LOGIN_HIDDEN} />
    </div>
  );
}
