import clsx from 'clsx';
import {LoadingSpinner} from '../CartLoading/components/LoadingSpinner';

export enum InputType {
  FORM = 'FORM',
  FORM_TEXTAREA = 'FORM_TEXTAREA',
  MINI_SEARCH = 'MINI_SEARCH',
  SEARCH = 'SEARCH',
  TEXT = 'TEXT',
}

interface Props {
  className?: string;
  label?: string;
  id?: string;
  type?: string;
  variant: InputType;
  [key: string]: any;
  validationError?: string;
  successMessage?: string;
}

export function Input({
  className = '',
  id,
  label,
  type,
  variant,
  validationError,
  successMessage,
  ...props
}: Props) {
  const variants = {
    SEARCH: `bg-transparent px-0 py-2 text-heading w-full focus:ring-0 border-x-0 border-t-0
            transition border-b-2 border-primary focus:border-primary/90`,
    MINI_SEARCH: `bg-transparentinline-block text-left border-b
                  transition border-transparent -mb-px border-x-0 border-t-0
                  appearance-none px-0 py-1 focus:ring-transparent placeholder:opacity-20 placeholder:text-inherit`,
    FORM: `w-full relative field_input bg-transparent inline-block text-left transition
                  border border-primary -mb-px appearance-none px-[2rem] py-[2rem]
          focus:ring-transparent text-[2rem]`,
    FORM_TEXTAREA: `w-full relative field_input bg-transparent inline-block text-left transition
    border border-primary -mb-px appearance-none px-[2rem] pt-[2rem]
pb-[0.5rem] focus:ring-transparent text-[2rem]`,
    TEXT: `w-full relative field_input bg-transparent inline-block text-left transition
          border-transparent -mb-px border-x-0 border-y-0 appearance-none px-0 pt-[1rem]
          pb-[0.5rem] focus:ring-transparent`,
  };

  const styles = clsx(variants[variant], className);

  function getInputMarkup() {
    switch (variant) {
      case InputType.TEXT:
        return (
          <>
            <input
              type={type}
              placeholder={' '}
              {...props}
              id={id}
              className={styles}
            />
            <label className="field_label" htmlFor={id}>
              {label}
            </label>
            {validationError ? (
              <label className="field_error" htmlFor={id}>
                {validationError}
              </label>
            ) : null}
            {successMessage ? (
              <label className="field_success" htmlFor={id}>
                {successMessage}
              </label>
            ) : null}
          </>
        );
      case InputType.FORM:
        return (
          <div className="relative w-full">
            <input
              type={type}
              placeholder={' '}
              {...props}
              id={id}
              className={styles}
            />{' '}
            <label className="field_label" htmlFor={id}>
              {label}
            </label>
          </div>
        );
      case InputType.FORM_TEXTAREA:
        return (
          <div className="relative w-full">
            <textarea placeholder={' '} {...props} id={id} className={styles} />{' '}
            <label className="field_label" htmlFor={id}>
              {label}
            </label>
          </div>
        );
      default:
        return <input type={type} {...props} className={styles} />;
    }
  }

  return getInputMarkup();
}
