import en from './translations/en.json';

export const HighIndexTitle = () => {
  return (
    <h2 className={'flex flex-col'}>
      {/* Desktop text */}
      <span
        className={`hidden md:block bg-[radial-gradient(138.06%_1036.51%_at_95.25%_-2.54%,_#7ED4FD_14.06%,#709DF7_51.02%,#4D78EF_79.09%)] text-center indexBreak:text-left
              text-[7rem] font-light mb-[-2rem] bg-clip-text text-transparent`}
      >
        {en.HighIndexTitle.firstLine}
      </span>
      <span
        className={`hidden md:block text-[5rem] font-light bg-clip-text text-transparent leading-[5.5rem] mb-[1rem] text-center indexBreak:text-left
              bg-[radial-gradient(138.06%_1036.51%_at_95.25%_-2.54%,_#7ED4FD_14.06%,#709DF7_51.02%,#4D78EF_79.09%)]`}
      >
        {en.HighIndexTitle.secondLine}
      </span>
      {/* Mobile text */}
      <span
        className={`block md:hidden text-center text-[4rem] sm:text-[5rem] font-light mx-[2.5rem] mb-[2rem] leading-[5rem] sm:leading-[6.5rem]
        bg-clip-text text-transparent
        bg-[radial-gradient(138.06%_1036.51%_at_95.25%_-2.54%,_#7ED4FD_14.06%,#709DF7_51.02%,#4D78EF_79.09%)]`}
      >
        {en.HighIndexTitle.combined}
      </span>
    </h2>
  );
};
